import { useStaticQuery, graphql } from 'gatsby';

export const usePageDownloadData = (lang: string) => {
  const data = useStaticQuery(graphql`
    query PageDownload {
      wpPage(id: { eq: "cG9zdDo2NTI=" }) {
        id
        downloadPage {
          nazevStranky
          perex
          souborydownload {
            barva
            image {
              mediaItemUrl
            }
            nazev
            perex
            soubor {
              mediaItemUrl
            }
          }
        }
      }
    }
  `);

  return data.wpPage;
};
