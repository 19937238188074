import LayoutProductPage from '../../components/layouts/ProductPage';
import * as style from '../../styles/pages/productIndex.module.scss';
import * as dstyle from '../../styles/pages/download.module.scss';
import * as gstyle from '../../styles/styles.module.scss';
import Footer from '../../components/Footer';
import React from 'react';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';
import { pageDownloadData } from '../../data/page-download.data';
import { ProductPageMetadata } from '../../data/page-product.data';
export default function index() {
  const data = pageDownloadData();
  const product = ProductPageMetadata();
  return (
    <section>
      <Helmet title="TALXIS Ke stažení"> </Helmet>
      <LayoutProductPage
        backLink="/"
        header={data.downloadPage.nazevStranky}
        subHeader="TALXIS"
        color="purple"
        copy={product.copy}
      >
        <section className={style.productList}>
          {data.downloadPage.souborydownload.map((file: any, key: number) => (
            <section className={classnames(style.product, style[file.barva])} key={key}>
              <div className={classnames(gstyle.center, style.center)}>
                <div
                  className={classnames(dstyle.image, style.image)}
                  style={{ backgroundImage: `url(${file?.image?.mediaItemUrl})` }}
                ></div>
                <div className={style.copy}>
                  <h1>{file.nazev}</h1>
                  <p>{file.perex}</p>
                  <a
                    target="_blank"
                    href={file.soubor?.mediaItemUrl}
                    className={dstyle.downloadButton}
                  >
                    Stáhnout
                  </a>
                </div>
              </div>
            </section>
          ))}
        </section>
      </LayoutProductPage>
      <section className={style.footerSection}>
        <Footer></Footer>
      </section>
    </section>
  );
}
